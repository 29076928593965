import { useEffect } from 'react';

const Security = () => {
  useEffect(() => {
    if (window.location.pathname === '/security') {
      window.location.href = 'https://vitalizecare.notion.site/Security-at-Vitalize-Care-39b2aa52e65a419f88bf58f01432990d';
    }
  }, []);

  return null;
};

export default Security;